import {FormikProps} from "formik";
import React, {FC, memo, useState} from "react";
import {
    Button,
    Card,
    Checkbox,
    DatePicker,
    Form,
    Modal,
    SegmentedProps,
    Space,
    Switch,
} from "antd";
import moment from "moment";
import {InfoCircleOutlined} from "@ant-design/icons";
import {PosibleFormikProps} from "./EditRestrictiveBooking";
import css from "./EditRestrictiveBookings.less";
import {createInputHelpers} from "../../../helpers/createInputHelpers";
import PrerequisiteCoursesInput from "./PrerequisiteCoursesInput";
import Tag from "../../../views/Tag/Tag";
import RestrictiveBookingsWiki from "./RestrictiveBookingsWiki";
import {ExpireAction} from "../../../generated/graphql";
import i18n from "../../../services/i18n";

type GeneralSettingsProps = {
    formikProps: FormikProps<PosibleFormikProps>;
};

const GeneralSettings: FC<GeneralSettingsProps> = ({formikProps}) => {
    const {values} = formikProps;

    const {handleNumberInputChange} = createInputHelpers(formikProps);

    const {startDateTime, endDateTime} = values;

    const blockedPlaces =
        values.blockedPlaces === null || values.blockedPlaces === undefined
            ? 0
            : Number(values.blockedPlaces);

    const startDateTimeValue =
        startDateTime === null ? undefined : moment(startDateTime);
    const endDateTimeValue =
        endDateTime === null ? undefined : moment(endDateTime);

    const handleTimeRangeChange = ({
        field,
        date,
    }: {
        field: "start" | "end";
        date: moment.Moment | null;
    }) => {
        if (date === null) {
            if (field === "start") {
                formikProps.setFieldValue("startDateTime", date);
            } else {
                formikProps.setFieldValue("endDateTime", date);
            }

            return;
        }

        // Make sure that the start date is before the end date
        if (
            field === "start" &&
            date &&
            (date.isBefore(endDateTime) ||
                endDateTime === startDateTime ||
                !endDateTime)
        ) {
            const startOfDay = date.startOf("day");

            formikProps.setFieldValue("startDateTime", startOfDay);
        } else if (
            field === "end" &&
            date &&
            (date.isAfter(startDateTime) ||
                endDateTime === startDateTime ||
                !startDateTime)
        ) {
            const endOfDay = date.endOf("day");

            formikProps.setFieldValue("endDateTime", endOfDay);
        }
    };

    return (
        <Card
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "baseline",
                    }}
                >
                    {/* <div>{"Allgemeine Einstellungen"}</div> */}
                    <Button
                        size="small"
                        type="link"
                        onClick={() =>
                            Modal.info({
                                title: "Erklärung zu Buchungsbeschränkungen",
                                content: <RestrictiveBookingsWiki />,
                                width: 800,
                                closable: true,
                            })
                        }
                    >
                        <Space>
                            <InfoCircleOutlined />
                            {"Erklärung zur Buchungsbeschränkung"}
                        </Space>
                    </Button>
                </div>
            }
            className={css.card}
        >
            <ExpireActionInput formikProps={formikProps} />
            <div className={css.masterCardContent}>
                <Form.Item label="Start" style={{gridColumn: "1 / span 1"}}>
                    <DatePicker
                        format="dd DD.MM.YYYY"
                        style={{width: "100%"}}
                        value={startDateTimeValue}
                        onChange={(date) =>
                            handleTimeRangeChange({field: "start", date})
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="Ende" style={{gridColumn: "2 / span 1"}}>
                    <DatePicker
                        format="dd DD.MM.YYYY"
                        style={{width: "100%"}}
                        value={endDateTimeValue}
                        onChange={(date) =>
                            handleTimeRangeChange({field: "end", date})
                        }
                        allowClear
                    />
                </Form.Item>
                {/* <Tooltip title="Noch nicht implementiert">
                    <Form.Item
                        label="Anzahl blockierter Plätze"
                        style={{gridColumn: "1 / span 2"}}
                    >
                        <InputNumber
                            style={{width: "100%"}}
                            value={blockedPlaces}
                            onChange={(value) => {
                                if (typeof value === "number") {
                                    handleNumberInputChange("blockedPlaces")(
                                        value,
                                    );
                                }
                            }}
                            disabled
                        />
                    </Form.Item>
                </Tooltip> */}
            </div>
        </Card>
    );
};

export const GeneralSettingsCard = memo(GeneralSettings);

const MemberRestriction: FC<GeneralSettingsProps> = ({formikProps}) => {
    const {values} = formikProps;

    const handleSwitchChange = (value: boolean) => {
        formikProps.setFieldValue("membersOnly", value);
    };

    return (
        <Card
            title="Buchbarkeit auf Mitglieder begrenzen"
            extra={
                <Switch
                    onChange={handleSwitchChange}
                    checked={values.membersOnly}
                />
            }
            type="inner"
            className={css.card}
            bodyStyle={{padding: 0 /* , marginBottom: -15 */}}
        />
    );
};

export const MemberRestrictionCard = memo(MemberRestriction);

const ExpireActionInput: FC<GeneralSettingsProps> = ({formikProps}) => {
    const {values} = formikProps;

    const [expireAction, setExpireAction] = useState<ExpireAction>(
        values.expireAction as ExpireAction,
    );

    const handleExpireActionChange = (value: string) => {
        formikProps.setFieldValue("expireAction", value);
        setExpireAction(value as ExpireAction);
    };

    const Segmented = require("antd").Segmented;

    const options = [
        {
            label: i18n.containers.restrictiveBooking.RestrictiveBookingCreateEdit.expireAction.showToAll(),
            value: ExpireAction.ShowToAll,
        },
        {
            label: i18n.containers.restrictiveBooking.RestrictiveBookingCreateEdit.expireAction.hideFromAll(),
            value: ExpireAction.HideFromAll,
        },
    ];

    const segmentedProps: SegmentedProps = {
        disabled: !values.endDateTime,
        options,
        value: expireAction,
        onChange: (value) => handleExpireActionChange(value as ExpireAction),
        block: true,
    };

    return (
        <Form.Item
            label={i18n.containers.restrictiveBooking.RestrictiveBookingCreateEdit.expireAction.label()}
            style={{gridColumn: "1 / span 2"}}
        >
            <Segmented {...segmentedProps} />
        </Form.Item>
    );
};

export const ExpireActionCard = memo(ExpireActionInput);

const PrerequisiteCourses: FC<GeneralSettingsProps> = ({formikProps}) => {
    const {values} = formikProps;

    const disabled =
        !values.prerequisiteCourses || values.prerequisiteCourses.length === 0;

    const ExtraTag = () => {
        const text = disabled ? "Inaktiv" : "Aktiv";

        return <Tag color={disabled ? "red" : "green"}>{text}</Tag>;
    };

    return (
        <Card
            title="Freigabekurse"
            // type="inner"
            className={css.card}
            extra={<ExtraTag />}
        >
            <div className={css.cardContent}>
                <Form.Item>
                    <Checkbox
                        checked={Boolean(values.includeBookingList)}
                        onChange={(e) =>
                            formikProps.setFieldValue(
                                "includeBookingList",
                                e.target.checked,
                            )
                        }
                        disabled={disabled}
                    >
                        {"Buchungen berücksichtigen"}
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={Boolean(values.includeWaitingList)}
                        onChange={(e) =>
                            formikProps.setFieldValue(
                                "includeWaitingList",
                                e.target.checked,
                            )
                        }
                        disabled={disabled}
                    >
                        {"Warteliste berücksichtigen"}
                    </Checkbox>
                </Form.Item>
                <PrerequisiteCoursesInput
                    formikProps={formikProps}
                    style={{gridColumn: "1 / span 2"}}
                />
            </div>
        </Card>
    );
};

export const PrerequisiteCoursesCard = memo(PrerequisiteCourses);

const Visibility: FC<GeneralSettingsProps> = ({formikProps}) => {
    const {values} = formikProps;

    const handleSwitchChange = (value: boolean) => {
        formikProps.setFieldValue("visibleForAll", value);
    };

    return (
        <Card
            title="Kurse für alle sichtbar halten"
            extra={
                <Switch
                    onChange={handleSwitchChange}
                    checked={values.visibleForAll}
                />
            }
            type="inner"
            className={css.card}
            bodyStyle={{padding: 0 /* , marginBottom: -15 */}}
        />
    );
};

export const VisibilityCard = memo(Visibility);
