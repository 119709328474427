import React, {FC, memo} from "react";
import {Maybe} from "type-graphql";
import {Card, Divider, Space} from "antd";
import moment from "moment";
import {green, orange} from "@ant-design/colors";
import {
    Course,
    GetRestrictiveBookingsByCourseIdQuery,
} from "../../../generated/graphql";
import SelectedCoursesPreview from "./SelectedCoursesPreview";
import css from "./EditRestrictiveBookings.less";
import {
    MemberIconSvg,
    PrerequisiteCoursesIconSvg,
} from "../../../assets/SVG-Icons";

type PreviewSmallProps = {
    courseId: Maybe<Course["id"]> | undefined;
    existingRestrictiveBooking?: GetRestrictiveBookingsByCourseIdQuery["getBookingRestrictionByCourseId"];
};

const PreviewSmall: FC<PreviewSmallProps> = ({
    courseId,
    existingRestrictiveBooking,
}) => {
    if (!existingRestrictiveBooking) {
        return null;
    }

    const {
        prerequisiteCourses,
        membersOnly,
        startDateTime,
        endDateTime,
    } = existingRestrictiveBooking;

    const startAndEndAreInThePast = () => {
        // return moment().isAfter(startDateTime) && moment().isAfter(endDateTime);
        // Wenn nur endDateTime gesetzt ist und in der Vergangenheit liegt
        if (!startDateTime && endDateTime && moment().isAfter(endDateTime)) {
            return true;
        }

        // Wenn nur startDateTime gesetzt ist und in der Vergangenheit liegt
        if (startDateTime && !endDateTime && moment().isAfter(startDateTime)) {
            return true;
        }

        // Wenn beide Daten gesetzt sind und in der Vergangenheit liegen
        if (startDateTime && endDateTime) {
            return (
                moment().isAfter(startDateTime) && moment().isAfter(endDateTime)
            );
        }
    };

    if (startAndEndAreInThePast()) {
        return (
            <div className={css.settingsInThePast}>
                {"Einstellungen sind abgelaufen"}
            </div>
        );
    }

    if (!existingRestrictiveBooking.active) {
        return null;
    }

    const renderTimeRange = () => {
        const start = moment(startDateTime).format("dd DD.MM.YYYY");
        const end = moment(endDateTime).format("dd DD.MM.YYYY");

        if (startDateTime && endDateTime) {
            return (
                <Space>
                    {"Einstellungen gelten"}
                    <Space>{`von ${start} bis ${end}`}</Space>
                </Space>
            );
        } else if (startDateTime) {
            return <Space>{`Einstellungen gelten ab ${start}`}</Space>;
        } else if (endDateTime) {
            return <Space>{`Einstellungen gelten bis ${end}`}</Space>;
        }

        return null;
    };

    return (
        <Card size="small" style={{marginTop: 5}}>
            <Space direction="vertical" style={{width: "100%"}}>
                {renderTimeRange()}
                {membersOnly && (
                    <>
                        <Divider style={{margin: "0"}} />
                        <Space style={{alignContent: "baseline"}}>
                            <MemberIconSvg color={green[5]} size={32} />
                            {"Nur für Mitglieder buchbar"}
                        </Space>
                    </>
                )}
                {prerequisiteCourses && (
                    <>
                        <Divider style={{margin: "0"}} />
                        <Space style={{alignItems: "flex-start"}}>
                            <PrerequisiteCoursesIconSvg
                                color={orange[5]}
                                size={25}
                            />
                            {"Freigabekurse:"}
                            <SelectedCoursesPreview
                                courseIds={prerequisiteCourses.map(
                                    (course) => course.id,
                                )}
                            />
                        </Space>
                        <Divider style={{margin: "0"}} />
                    </>
                )}
            </Space>
        </Card>
    );
};

export default memo(PreviewSmall);
