import {PaymentMethod} from "../generated/graphql";

// /////////////////////////////////////////////////////////////////////////////////////////////// //
// ACHTUNG: Dieser Code ist identisch mit dem Code in src/server/lib/getPaymentMethodType.ts       //
// Wir sollten diesen Code in ein gemeinsames Modul auslagern, um die Wartbarkeit zu erhöhen.      //
// /////////////////////////////////////////////////////////////////////////////////////////////// //

export type PaymentMethodType =
    | "cash"
    | "creditCard"
    | "payPal"
    | "sepaDebit"
    | "esr"
    | "bankTransfer"
    | "none";

export type PaymentMethodData = Pick<
    PaymentMethod,
    | "cash"
    | "esr"
    | "creditCardAccountholder"
    | "creditcardNumber"
    | "expirationDate"
    | "validFrom"
    | "cvc"
    | "paypalAccountholder"
    | "email"
    | "iban"
    | "bank"
    | "bic"
    | "sepaAccountholder"
    | "bankTransfer"
>;

export const getPaymentMethodType = ({
    cash,
    esr,
    creditcardNumber,
    creditCardAccountholder,
    expirationDate,
    sepaAccountholder,
    iban,
    bic,
    bank,
    paypalAccountholder,
    email,
    bankTransfer,
}: PaymentMethodData): PaymentMethodType => {
    if (cash !== null && cash !== undefined) {
        return "cash";
    }

    if (bankTransfer === true) {
        return "bankTransfer";
    }

    if (
        creditcardNumber !== null &&
        creditcardNumber !== undefined &&
        creditCardAccountholder !== null &&
        creditCardAccountholder !== undefined &&
        expirationDate !== null &&
        expirationDate !== undefined
    ) {
        return "creditCard";
    }

    if (
        sepaAccountholder !== null &&
        sepaAccountholder !== undefined &&
        iban !== null &&
        iban !== undefined &&
        bic !== null &&
        bic !== undefined &&
        bank !== null &&
        bank !== undefined
    ) {
        return "sepaDebit";
    }

    if (
        paypalAccountholder !== null &&
        paypalAccountholder !== undefined &&
        email !== null &&
        email !== undefined
    ) {
        return "payPal";
    }

    if (esr === true) {
        return "esr";
    }

    return "none";
};
