import React, {FC, memo, useEffect, useState} from "react";
import Maybe from "graphql/tsutils/Maybe";
import {Button, Divider, Space, Tooltip} from "antd";
import {InfoCircleOutlined, RedoOutlined} from "@ant-design/icons";
import {blue} from "@ant-design/colors";
import {
    Course,
    CourseIsPrerequisiteCourseForQuery,
    EnabledCourse,
    useCourseIsPrerequisiteCourseForQuery,
    useGetReferenceCoursesLinkQuery,
} from "../../../generated/graphql";
import {RenderEnabledCourse} from "../../../views/TableColumnRenderers/BiggerComponents/CellEnabledCourses/RenderEnabledCourse";
import i18n from "../../../services/i18n";
import ReferenceCourseLinkPreview from "./ReferenceCourseLinkPreview";

type CourseIsPrerequisiteForCoursesProps = {
    courseId: Maybe<Course["id"]> | undefined;
    prefixedCourseNumber: string | undefined;
};

const CourseIsPrerequisiteForCourses: FC<CourseIsPrerequisiteForCoursesProps> = ({
    courseId,
    prefixedCourseNumber,
}) => {
    const [referenceCourseLink, setReferenceCourseLink] = useState<
        string | null
    >(null);
    const [showPreview, setShowPreview] = useState(false);
    const {data} = useGetReferenceCoursesLinkQuery({
        skip: !courseId,
        variables: {courseId: courseId ?? ""},
    });

    useEffect(() => {
        if (data) {
            setReferenceCourseLink(data.getReferenceCoursesLink);
        }
    }, [data]);

    const {
        data: prerequisiteCoursesForThisCourse,
        refetch,
        loading: prerequisiteCoursesForThisCourseLoading,
    } = useCourseIsPrerequisiteCourseForQuery({
        skip: !courseId,
        variables: {courseId: courseId ?? ""},
    });

    const courseIsPrerequisiteCourseFor:
        | CourseIsPrerequisiteCourseForQuery["courseIsPrerequisiteCourseFor"]
        | undefined =
        prerequisiteCoursesForThisCourse?.courseIsPrerequisiteCourseFor;

    const courseEnablesAtLeastOneCourse =
        courseIsPrerequisiteCourseFor &&
        courseIsPrerequisiteCourseFor.length > 0;

    const RefetchButton = () => {
        return (
            <Button
                type="link"
                onClick={async () => refetch({courseId: courseId ?? ""})}
                loading={prerequisiteCoursesForThisCourseLoading}
            >
                <RedoOutlined />
            </Button>
        );
    };

    if (!courseIsPrerequisiteCourseFor || !courseEnablesAtLeastOneCourse) {
        return (
            <Space size={0}>
                <RefetchButton />
                <div>{"Dieser Kurs ist selbst kein Freigabekurs"}</div>
            </Space>
        );
    }

    return (
        <div style={{padding: "5px 5px"}}>
            <Divider style={{margin: "10px 0"}}>{"Freigabekurs"}</Divider>
            <Space style={{fontSize: 14, fontWeight: 500}}>
                <Tooltip
                    title={i18n.containers.courses.CourseCreate.prerequisiteCourse.description()}
                    placement="topRight"
                >
                    <InfoCircleOutlined style={{color: blue[5]}} />
                </Tooltip>
                {prefixedCourseNumber
                    ? i18n.containers.courses.CourseCreate.prerequisiteCourse.headerWithPrefixedCourseNumber(
                          {prefixedCourseNumber},
                      )
                    : i18n.containers.courses.CourseCreate.prerequisiteCourse.header()}
            </Space>
            <Space style={{fontSize: 12}}>
                {i18n.containers.courses.CourseCreate.prerequisiteCourse.subHeader()}
            </Space>
            <div style={{fontSize: 14, fontWeight: 500, paddingTop: 10}}>
                {i18n.containers.courses.CourseCreate.prerequisiteCourse.enabledCourses()}
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 6,
                    fontSize: 10,
                    padding: 5,
                    border: "1px solid #e0e0e0",
                    borderRadius: 4,
                }}
            >
                {courseIsPrerequisiteCourseFor.map((course) => (
                    <>
                        <RenderEnabledCourse
                            enabledCourse={course as EnabledCourse}
                            // enabledCourse={undefined}
                            prerquisiteCourseId={courseId ?? ""}
                            key={course.id}
                            refetch={refetch}
                        />
                    </>
                ))}
            </div>
            <div>
                <Space>
                    <Tooltip
                        title={i18n.containers.courses.CourseCreate.prerequisiteCourse.previewTooltip()}
                    >
                        <InfoCircleOutlined style={{color: blue[5]}} />
                    </Tooltip>
                    <a href={referenceCourseLink ?? "#"} target="_blank">
                        {i18n.containers.courses.CourseCreate.prerequisiteCourse.previewNewTab()}
                    </a>

                    <Tooltip title="Diese Funktion ist noch nicht verfügbar">
                        <Button
                            type="link"
                            onClick={() => setShowPreview(true)}
                            disabled
                        >
                            {"Vorschau öffnen"}
                        </Button>
                    </Tooltip>
                </Space>

                {referenceCourseLink && (
                    <ReferenceCourseLinkPreview
                        url={referenceCourseLink}
                        visible={showPreview}
                        onClose={() => setShowPreview(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(CourseIsPrerequisiteForCourses);
