import React, {FC, memo} from "react";
import {Redirect} from "react-router";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import {red} from "@ant-design/colors";
import Button from "../../views/Button/Button";
import {
    useLogoutMutation,
    useAuthenticateQuery,
    useGetCompanyInfoQuery,
    GetCompanyInfoQuery,
    SubscriptionType,
} from "../../generated/graphql";
import {URL_LOGIN} from "../../models/url";
import i18n from "../../services/i18n";
import SubscriptionBadge from "../../views/SubscriptionBadge/SubscriptionBadge";
import Roles from "../../../shared/models/roles";
import css from "../../views/LayoutStandard/LayoutStandard.less";

type LogoutProps = {highestRole?: Roles; menuClosed?: boolean};

const Logout: FC<LogoutProps> = ({highestRole, menuClosed}) => {
    const [logout, {data: logoutData, loading, client}] = useLogoutMutation({
        onCompleted: () => {
            client?.cache.reset();
        },
    });

    const {data: userData} = useAuthenticateQuery();

    const {data: companyInfoData} = useGetCompanyInfoQuery();

    const companyInfo: GetCompanyInfoQuery["companyInfo"] | undefined =
        companyInfoData?.companyInfo;

    const subscriptionType =
        SubscriptionType[
            companyInfo?.subscriptionType ?? SubscriptionType.TestSubscription
        ];

    const companyLogo = companyInfo?.companySettings?.logo;
    const loadCompanyLogo = (): string => {
        const logoBase64 = companyInfo?.companySettings?.logo;

        if (!logoBase64) {
            return "";
        }
        const logo = `data:image/png;base64,${logoBase64}`;

        return logo;
    };

    if (
        logoutData?.logoutUser === true ||
        userData?.authenticate === undefined
    ) {
        return <Redirect to={URL_LOGIN} />;
    }

    const menuClosedWidth = 80;

    const extractUppercaseLetters = (str: string) => {
        if (!str) {
            return "";
        }
        // First, try to match uppercase letters
        let letters = str.match(/[A-ZÄÖÜ]/g) ?? [];

        // If no uppercase letters are found, use the first letter
        if (letters.length === 0) {
            letters = [str[0]];
        }

        // Add any letter that follows a space or a hyphen
        const additionalLetters = str.match(/[ -][A-Za-zÄÖÜäöü]/g) ?? [];

        letters = letters.concat(
            additionalLetters.map((letter) => letter.trim()),
        );

        // Returning the first 3 characters, or fewer if not available
        return letters.slice(0, 3).join("");
    };

    const uperCaseLettersOfcompanyName = companyInfo?.name
        ? extractUppercaseLetters(companyInfo.name)
        : "";

    if (menuClosed) {
        return (
            <>
                <Avatar size="small" icon={<UserOutlined />} />
                {highestRole && (
                    <div>
                        {i18n.containers.common.Roles.short[highestRole]()}
                    </div>
                )}
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: "1.1em",
                        width: menuClosedWidth,
                        textAlign: "center",
                    }}
                >
                    {uperCaseLettersOfcompanyName}
                </div>
                <Button
                    onClick={() => {
                        logout();
                    }}
                    disabled={loading}
                    // ghost
                    data-testid="LogoutButton"
                    icon={<LogoutOutlined />}
                    style={{width: menuClosedWidth, color: "white"}}
                    type="text"
                />
                <SubscriptionBadge
                    subscriptionType={subscriptionType}
                    color={red[2]}
                    style={{marginTop: 10, marginBottom: -20}}
                    menuClosed={menuClosed}
                    showModal={false}
                />
            </>
        );
    }

    return (
        <>
            <Avatar size="small" icon={<UserOutlined />} />
            {highestRole && (
                <div>{i18n.containers.common.Roles[highestRole]()}</div>
            )}
            <div>{userData.authenticate.email}</div>
            <div>{userData.authenticate.firstName}</div>
            <div
                style={{
                    fontWeight: 600,
                    fontSize: "1.1em",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {companyInfo?.name}
            </div>
            {/* {companyLogo && (
                <div className={css.logoContainer}>
                    <img
                        className={css.companyLogo}
                        src={loadCompanyLogo()}
                        alt="logo-typographic"
                    />
                </div>
            )} */}
            <Button
                onClick={() => {
                    logout();
                }}
                disabled={loading}
                ghost
                data-testid="LogoutButton"
            >
                {i18n.containers.logout.Logout.logout()}
            </Button>
            <SubscriptionBadge
                subscriptionType={subscriptionType}
                color={red[2]}
                style={{marginTop: 10, marginBottom: -20}}
            />
        </>
    );
};

export default memo(Logout);
