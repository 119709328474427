import React, {memo, useState} from "react";
import {PlusCircleOutlined} from "@ant-design/icons";
import {grey} from "@ant-design/colors";
import {Modal, Tooltip} from "antd";
import {
    useAddBookableCoursesMutation,
    WithEnabledCourse,
} from "../../../../generated/graphql";
import {RenderEnabledCourse} from "./RenderEnabledCourse";
import AddCourseSelector from "./AddCourseSelector";

const CellEnabledCourses = ({
    prerquisiteCourseId,
    enabledCourses,
    bookingRestrictionId,
    refetch,
}: {
    prerquisiteCourseId: string;
    enabledCourses?: WithEnabledCourse["enabledCourses"];
    bookingRestrictionId?: string;
    refetch?: () => void;
}) => {
    const [selectCourseModalOpen, setSelectCourseModalOpen] = useState(false);
    const [selectedCourseIds, setSelectedCourseIds] = useState<Array<string>>(
        enabledCourses?.map((course) => course.id) ?? [],
    );

    React.useEffect(() => {
        console.log("selectedCourseIds:::", selectedCourseIds);
    }, [selectedCourseIds]);
    const [addBookableCourses] = useAddBookableCoursesMutation();

    if (!enabledCourses) {
        return <div>{"-"}</div>;
    }

    const onAddCourses = async () => {
        try {
            await addBookableCourses({
                variables: {
                    prerequisiteCourseId: prerquisiteCourseId,
                    bookableCourseIds: selectedCourseIds,
                },
            });
        } catch (error) {
            console.error("Error adding courses", error);
        } finally {
            refetch?.();
            setSelectCourseModalOpen(false);
        }
    };

    const selectCourseModal = () => {
        return (
            <Modal
                title="Kurs hinzufügen"
                width="100vw"
                style={{
                    padding: 0,
                    position: "static",
                }}
                open={selectCourseModalOpen}
                okText="Kurse speichern"
                cancelText="Abbrechen"
                onOk={async () => onAddCourses()}
                onCancel={() => setSelectCourseModalOpen(false)}
                destroyOnClose
            >
                {/* {bookingRestrictionId ? ( */}
                <AddCourseSelector
                    bookingRestrictionId={bookingRestrictionId}
                    currentCourseId={prerquisiteCourseId}
                    selectedCourseIds={selectedCourseIds}
                    setSelectedCourseIds={setSelectedCourseIds}
                />
                // ) : ( // <>{"Keine Freigabekurse gewählt"}</>
                // )}
            </Modal>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 4,
            }}
        >
            {enabledCourses.map((enabledCourse) => (
                <RenderEnabledCourse
                    enabledCourse={enabledCourse}
                    prerquisiteCourseId={prerquisiteCourseId}
                    key={enabledCourse.id}
                    refetch={refetch}
                />
            ))}
            <Tooltip title="Kurse hinzufügen">
                <PlusCircleOutlined
                    style={{
                        marginTop: 2,
                        fontSize: 18,
                        color: grey[1],
                    }}
                    onClick={() => setSelectCourseModalOpen(true)}
                />
            </Tooltip>
            {selectCourseModal()}
        </div>
    );
};

export default memo(CellEnabledCourses);
