import React, {FC} from "react";
import {Menu, MenuProps} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import classNames from "classnames";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {MainMenuProps, MenuNavigationItem} from "../types";
import css from "../LayoutStandard.less";
import Logout from "../../../containers/logout/Logout";
import {getMainMenuItems} from "../../../containers/common/MainMenuRoutes";
import {useGetCompanyInfoQuery} from "../../../generated/graphql";

const MainMenu: FC<MainMenuProps> = ({
    className,
    highestRole,
    rolesOfCurrent,
    isInstructor = false,
    menuTextClasses,
    closed,
    companyInfo,
    secondLevelNavigationItems,
    navigationIsOpen,
    isStage,
}) => {
    const history = useHistory();
    const location = useLocation();
    const mainMenuClasses = [css.mainMenu, className];
    const [openKeys, setOpenKeys] = React.useState<Array<string>>([]);
    const {data: companyInfoData} = useGetCompanyInfoQuery({
        // fetchPolicy: "network-only",
        fetchPolicy: "cache-first",
    });

    const featureWeekPlaner =
        companyInfoData?.companyInfo?.subscription?.featureWeekPlaner;

    const featurePrerequisiteCourses =
        companyInfoData?.companyInfo?.subscription?.featurePrerequisiteCourses;

    const isMenuItemActive = (itemPath: string): boolean => {
        return (
            location.pathname.startsWith(`${itemPath}/`) ||
            location.pathname === itemPath
        );
    };

    const addAuxClick = (link: string) => {
        return (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (e.button === 1) {
                window.open(link, "_blank");
            }
        };
    };

    const handleMenuClick = (link: string, childLink?: string) => (e: any) => {
        const mouseEvent = e.domEvent as MouseEvent;
        const targetLink = childLink ?? link;

        // Für mittlere Maustaste oder Cmd/Ctrl+Click öffnen wir in neuem Tab
        if (
            mouseEvent.button === 1 ||
            mouseEvent.ctrlKey ||
            mouseEvent.metaKey
        ) {
            window.open(targetLink, "_blank");

            return;
        }
        // Für normalen Klick nutzen wir die App-Navigation
        history.push(targetLink);
    };

    const mainMenuItems = (): MenuProps["items"] => {
        const items = Object.values(
            getMainMenuItems({
                highestRole,
                featureWeekPlaner,
                featurePrerequisiteCourses,
            }),
        ).map((item) => ({
            ...item,
            onClick: handleMenuClick(item.link),
            onTitleClick: handleMenuClick(item.link),
            onAuxClick: addAuxClick(item.link),
            className: isMenuItemActive(item.link)
                ? isStage
                    ? classNames("ant-menu-item-selected", css.stage)
                    : "ant-menu-item-selected"
                : "",
        }));

        const itemsWithChildren = items.map((item) => {
            if (!item.children || item.children.length <= 1) {
                return {
                    ...item,
                    children: undefined,
                    onTitleClick: handleMenuClick(
                        item.link,
                        item.children?.length === 1
                            ? item.children[0].link
                            : undefined,
                    ),
                    onAuxClick: addAuxClick(
                        item.children?.length === 1
                            ? item.children[0].link
                            : item.link,
                    ),
                };
            }

            return {
                ...item,
                popupClassName: isStage
                    ? classNames(css.mainMenuAntPopup, css.stage)
                    : css.mainMenuAntPopup,
                onTitleClick: handleMenuClick(item.link),
                onAuxClick: addAuxClick(item.link),
                children: item.children.map((child: MenuNavigationItem) => ({
                    ...child,
                    expandIcon: child.children?.length < 2 ? <></> : undefined,
                    onTitleClick: handleMenuClick(child.link),
                    onAuxClick: addAuxClick(child.link),
                    className: isMenuItemActive(child.link)
                        ? isStage
                            ? classNames("ant-menu-item-selected", css.stage)
                            : "ant-menu-item-selected"
                        : "",
                })),
            };
        });

        return itemsWithChildren;
    };

    return (
        <nav className={classNames(mainMenuClasses)}>
            <Menu
                items={mainMenuItems()}
                className={
                    navigationIsOpen
                        ? isStage
                            ? classNames(css.mainMenuAnt, css.stage)
                            : classNames(css.mainMenuAnt)
                        : isStage
                        ? classNames(
                              css.mainMenuAnt,
                              css.mainMenuAntClosed,
                              css.stage,
                          )
                        : classNames(css.mainMenuAnt, css.mainMenuAntClosed)
                }
                theme="dark"
                forceSubMenuRender
                subMenuCloseDelay={0.2}
                openKeys={openKeys}
                selectedKeys={[location.pathname]}
                onOpenChange={(openKeys) => {
                    setOpenKeys(openKeys);
                }}
            />
            <div className={css.logout}>
                <Logout highestRole={highestRole} menuClosed={closed} />
            </div>
        </nav>
    );
};

export default MainMenu;
