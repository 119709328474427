import React, {memo} from "react";
import {Space, Tooltip} from "antd";
import {
    CheckCircleFilled,
    ClockCircleFilled,
    CloseCircleFilled,
    WarningFilled,
} from "@ant-design/icons";
import {green, orange, red} from "@ant-design/colors";
import {
    CourseNotVisibleReason,
    CourseVisibilityResult,
} from "../../generated/graphql";
import {
    MemberIconSvg,
    PrerequisiteCoursesIconSvg,
} from "../../assets/SVG-Icons";
import i18n from "../../services/i18n";

const stdIconStyle = (sizeMultiplier: number) => {
    const fontSize = 1.7 * sizeMultiplier + "em";

    return {
        color: orange[5],
        fontSize,
        paddingLeft: 2,
    };
};

const tooltipConfig = {
    placement: "top" as const,
    trigger: ["click", "hover"],
};

export const renderReasonIcon = ({
    reason,
    hideAfterDays,
    sizeMultiplier,
    style,
}: {
    reason: CourseNotVisibleReason;
    hideAfterDays: number;
    sizeMultiplier: number;
    style?: React.CSSProperties;
}) => {
    // console.log("sizeMultiplier", sizeMultiplier);
    switch (reason) {
        case CourseNotVisibleReason.HideAfterDays:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.hideAfterDays(
                        {hideAfterDays: hideAfterDays.toFixed(0)},
                    )}
                    {...tooltipConfig}
                >
                    <ClockCircleFilled
                        style={{
                            ...stdIconStyle(sizeMultiplier),
                            color: red[3],
                            ...style,
                        }}
                    />
                </Tooltip>
            );
        case CourseNotVisibleReason.MembersOnly:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.membersOnly()}
                    {...tooltipConfig}
                >
                    <div>
                        <MemberIconSvg
                            color={orange[5]}
                            style={{paddingBottom: 6, ...style}}
                            size={30 * sizeMultiplier}
                        />
                    </div>
                </Tooltip>
            );
        case CourseNotVisibleReason.PrerequisiteCourses:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.prerequisiteCourses()}
                    {...tooltipConfig}
                >
                    <div>
                        <PrerequisiteCoursesIconSvg
                            color={orange[5]}
                            size={24 * sizeMultiplier}
                            style={{paddingTop: 3, marginLeft: 2, ...style}}
                        />
                    </div>
                </Tooltip>
            );
        case CourseNotVisibleReason.LocationIsPublic:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.locationIsPublic()}
                    {...tooltipConfig}
                >
                    <CloseCircleFilled
                        style={{
                            ...stdIconStyle(sizeMultiplier),
                            color: red[3],
                            ...style,
                        }}
                    />
                </Tooltip>
            );
        case CourseNotVisibleReason.CourseShowInWeb:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.courseShowInWeb()}
                    {...tooltipConfig}
                >
                    <CloseCircleFilled
                        style={{
                            ...stdIconStyle(sizeMultiplier),
                            color: red[3],
                            ...style,
                        }}
                    />
                </Tooltip>
            );
        case CourseNotVisibleReason.CourseTypeShowInWeb:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.courseTypeShowInWeb()}
                    {...tooltipConfig}
                >
                    <CloseCircleFilled
                        style={{
                            ...stdIconStyle(sizeMultiplier),
                            color: red[3],
                            ...style,
                        }}
                    />
                </Tooltip>
            );
        case CourseNotVisibleReason.HideAfterRestrictionEnd:
            return (
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.hideAfterRestrictionEnd()}
                    {...tooltipConfig}
                >
                    <WarningFilled
                        style={{...stdIconStyle(sizeMultiplier), ...style}}
                    />
                </Tooltip>
            );
        default:
            return (
                <Space>
                    <WarningFilled
                        style={{...stdIconStyle(sizeMultiplier), ...style}}
                    />
                    {reason}
                </Space>
            );
    }
};

export const renderVisibility = (
    courseVisibilityResult: CourseVisibilityResult,
    sizeMultiplier = 1,
) => {
    const {visibleForAll, reason, hideAfterDays} = courseVisibilityResult;

    const fontSize = 1.7 * sizeMultiplier + "em";

    if (visibleForAll) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Tooltip
                    title={i18n.containers.courses.Courses.visibility.visibleForAll()}
                >
                    <CheckCircleFilled
                        style={{
                            ...stdIconStyle(sizeMultiplier),
                            fontSize,
                            color: green[3],
                        }}
                    />
                </Tooltip>
            </div>
        );
    }

    return (
        <Space direction="horizontal" size={4 * sizeMultiplier}>
            {reason.map((reason) => (
                <div key={reason}>
                    {renderReasonIcon({
                        reason,
                        hideAfterDays: hideAfterDays ?? 0,
                        sizeMultiplier,
                    })}
                </div>

                // <RenderReasonIcon key={reason} reason={reason} />
            ))}
        </Space>
    );
};

const RenderVisibility = ({
    courseVisibilityResult,
    sizeMultiplier = 1,
    style,
}: {
    courseVisibilityResult: CourseVisibilityResult;
    sizeMultiplier?: number;
    style?: React.CSSProperties;
}) => {
    return (
        <div style={style}>
            {renderVisibility(courseVisibilityResult, sizeMultiplier)}
        </div>
    );
};

export default memo(RenderVisibility);
