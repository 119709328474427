import React from "react";
import App from "./containers/common/App";
import ErrorHandler from "./containers/common/ErrorHandler";
import "./app.less";
import dotenv from "dotenv";

dotenv.config();

const app = (
    <ErrorHandler>
        <App />
    </ErrorHandler>
);

export default app;
