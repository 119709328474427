import dotenv from "dotenv";

dotenv.config();

export const ENV_PRODUCTION = "production";
export const ENV_TESTING = "test";

export const ENV_DEVELOPMENT = "development";
export const ENV_STAGING = "stage";

// export const MAX_UPLOAD_FILE_SIZE = 50e6; // 50 MB
// export const MAX_UPLOAD_FILE_SIZE = 100e6; // 100 MB
// export const MAX_UPLOAD_FILE_SIZE = 500e6; // 500 MB
export const MAX_UPLOAD_FILE_SIZE = 1000e6; // 1 GB

// The env object contains constants that are only known during execution.
// Using an object so that it can be easily mocked from a test.

// console.log("=============shared/const=============");
// // console.log("DEBUG::: NODE_ENV: ", process.env.NODE_ENV);
// // console.log("DEBUG::: DEPLOY_ENV: ", process.env.DEPLOY_ENV);
// console.log("NODE_ENV: ", process.env.NODE_ENV);
// console.log("DEPLOY_ENV: ", process.env.DEPLOY_ENV);
// console.log("APP_URL: ", process.env.APP_URL);
// console.log("API_URL: ", process.env.API_URL);
// console.log("DEV_ORGANIZATION_ID: ", process.env.DEV_ORGANIZATION_ID);
// // console.log(".env: ", process.env);
// console.log("=============shared/const=============");

// const env = {
//     IS_PRODUCTION: process.env.NODE_ENV === ENV_PRODUCTION,
//     IS_TESTING: process.env.NODE_ENV === ENV_TESTING,
//     IS_DEVELOPMENT: process.env.NODE_ENV === ENV_DEVELOPMENT,
//     IS_STAGING: process.env.NODE_ENV === ENV_STAGING,
//     DEV_ORGANIZATION_ID:
//         process.env.DEV_ORGANIZATION_ID ??
//         "69bca0e2-3aae-4ca5-9968-ea33f187938d",
//     DEPLOY_ENV: process.env.DEPLOY_ENV ?? "development",
//     NODE_ENV: process.env.NODE_ENV ?? "development",
//     NODE_ENV_X: process.env.NODE_ENV,
//     API_URL: process.env.API_URL,
// };

const env = {
    IS_PRODUCTION: process.env.NODE_ENV === ENV_PRODUCTION,
    IS_TESTING: process.env.NODE_ENV === ENV_TESTING,
    IS_DEVELOPMENT: process.env.NODE_ENV === ENV_DEVELOPMENT,
    IS_STAGING: process.env.NODE_ENV === ENV_STAGING,
    DEV_ORGANIZATION_ID: process.env.DEV_ORGANIZATION_ID,
    DEPLOY_ENV: process.env.DEPLOY_ENV,
    NODE_ENV: process.env.NODE_ENV,
    NODE_ENV_X: process.env.NODE_ENV,
    API_URL: process.env.API_URL,
};

export default env;
